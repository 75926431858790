import { Link } from 'gatsby'
import { css } from '@emotion/core'

const styleLogo = css`
  color: #fff;
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  width: 6.5rem;
  height: 1.5rem;
  text-indent: -9999px;
  background: url('/logo-normal.svg') center/contain no-repeat;
`

export const Logo = () => <Link css={styleLogo} to="/" />
