import React from 'react'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'

import { Logo } from './logo'
import { Icon } from '../icon'
import { Menu } from './mobile'
import { PageTitle } from '../typography'
import { LinkGroup } from './link-group'
import { ZoneSmall, ZoneLarge } from '../zoner'
import { ReactComponent as IconMenu } from '../../images/svg/baseline-menu-24.svg'

import { getPageName } from '../utils'

const StyledNav = styled('nav')`
  position: fixed;
  top: 0;
  width: 100vw;
  height: ${({ height }) => height || 7}rem;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1000;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Slot = styled('div')`
  flex: 0 1 ${({ width }) => width || 25}%;
  height: 100%;

  position: relative;
  display: flex;
  justify-content: ${({ align }) => align || 'center'};
  align-items: center;
  z-index: 1000;
`

export const NavLayout = ({
  height,
  left = null,
  right = null,
  center = null,
}) => {
  return (
    <StyledNav height={height}>
      <Slot align="flex-start">{left}</Slot>
      <Slot width={50}>{center}</Slot>
      <Slot align="flex-end">{right}</Slot>
    </StyledNav>
  )
}

const use = (component, fallback) =>
  typeof component === 'undefined' ? fallback : component

export const Nav = ({ left, center, right }) => {
  const { pathname } = useLocation()
  return (
    <>
      <ZoneSmall>
        <NavLayout
          left={<Menu />}
          center={<PageTitle>{getPageName(pathname)}</PageTitle>}
          right={right}
          height={4.5}
        />
      </ZoneSmall>
      <ZoneLarge>
        <NavLayout
          left={use(left, <Logo />)}
          center={use(center, <LinkGroup />)}
          right={right}
          height={7}
        />
      </ZoneLarge>
    </>
  )
}
