import React from 'react'
import styled from '@emotion/styled'
import { breakpoints } from './utils'

const makeMq = bps =>
  bps.map((bp, i) => {
    const nextBp = bps[i + 1]
    return `@media (min-width: ${bp}px) ${
      nextBp ? `and (max-width: ${nextBp - 1}px)` : ''
    }`
  })

export const mq = makeMq([0, ...breakpoints])

export const ZoneSmall = styled.div`
  display: none;

  ${mq[0]} {
    display: block;
  }
`

export const ZoneLarge = styled.div`
  display: block;

  ${mq[0]} {
    display: none;
  }
`
