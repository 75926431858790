import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'
import { fadeIn, fadeOut } from './utils'
import { Transition } from 'react-transition-group'

const getAnimationFromState = ({ state }) => {
  let animation
  switch (state) {
    case 'entering':
    case 'entered':
      animation = fadeIn
      break
    case 'exiting':
    case 'exited':
      animation = fadeOut
      break
  }
  return animation
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${({ background }) => background || 'rgba(0, 0, 0, 0.8)'};
  z-index: 2000;
  animation: ${getAnimationFromState} 0.3s ease-out;
  animation-fill-mode: both;
`

class Modal extends Component {
  state = {
    root: null,
  }

  componentDidMount() {
    const root = document.querySelector('#modal-root')
    this.setState({ root })
  }

  render() {
    const { root } = this.state
    const { isOpen, close, children, background } = this.props
    if (!root) return null
    return createPortal(
      <Transition mountOnEnter unmountOnExit in={isOpen} timeout={300}>
        {state => (
          <Overlay
            background={background}
            onClick={() => {
              if (close) close()
            }}
            state={state}
          >
            {children}
          </Overlay>
        )}
      </Transition>,
      root
    )
  }
}

export default Modal
