import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { SocialMedia } from './social'

export const StyledLinkA = styled.a`
  color: #fff;
  text-decoration: none;
  position: relative;
  & + & {
    margin-left: 0.75rem;
  }

  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%) scaleX(0.5);
    height: 1px;
    bottom: -0.35rem;
    background: white;
    transition: 0.3s ease;
    opacity: 0;
  }

  &:hover {
    &:before {
      transform: translateX(-50%) scaleX(0.8);
      opacity: 1;
    }
  }

  &.active {
    opacity: 0.6;
    &:before {
      opacity: 1;
    }
  }
`

export const StyledLink = StyledLinkA.withComponent(props => (
  <Link activeClassName="active" partiallyActive {...props} />
))

const styleLi = css`
  flex: 1 0 auto;
  text-align: center;
  padding: 0.25rem 0.5rem;
`

const styleUl = css`
  position: relative;
  width: 100%;
  max-width: 35rem;

  height: 100%;
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  justify-content: space-around;
`

export const Links = ({ children }) => (
  <ul css={styleUl}>
    {React.Children.map(children, (child, i) => (
      <li css={styleLi} key={i}>
        {child}
      </li>
    ))}
  </ul>
)

export const LinkGroup = () => (
  <Links>
    <StyledLink to="/work">Work</StyledLink>
    <StyledLink to="/contact">Contact</StyledLink>
    <StyledLink to="/company">Company</StyledLink>
    <SocialMedia size={1.25} />
  </Links>
)
