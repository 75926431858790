import React from 'react'
import { css } from '@emotion/core'

const Icon = ({ color, size, background, icon, label, rotation }) => (
  <div
    css={css`
      position: relative;
      display: inline-block;
      width: ${size}rem;
      vertical-align: middle;
      text-align: center;
      line-height: 1;
      transform: rotate(${rotation}deg);

      & > svg {
        display: block;
        ${background && 'background: rgba(255,255,255,.12);'}
        border-radius: ${size / 6}rem;
      }

      &:hover > svg {
        ${background && 'background: rgba(255,255,255,.2);'}
      }
    `}
  >
    {React.cloneElement(icon, {
      color,
      width: `${size}rem`,
      height: `${size}rem`,
    })}
    {label && (
      <span
        css={css`
          display: inline-block;
          margin-top: 0.75em;
          font-size: 0.75em;
        `}
      >
        {label}
      </span>
    )}
  </div>
)

Icon.defaultProps = {
  color: '#fff',
  size: 1.5,
  background: false,
  icon: false,
  label: '',
  rotation: 0,
}

export { Icon }

const styleButton = css`
  display: block;
  background: none;
  padding: 1rem;
  border: none;
  color: #fff;
  font-size: 1rem;
  line-height: 1.1;

  &:disabled {
    opacity: 0.2;
  }
`

export const IconButton = ({ onClick, children, disabled, ...iconProps }) => {
  return (
    <button css={styleButton} onClick={onClick} disabled={disabled}>
      {children} <Icon {...iconProps} />
    </button>
  )
}
