import styled from '@emotion/styled'

export const Header = styled.h1`
  font-size: 2rem;
  font-weight: normal;
  text-align: ${({ align }) => align || 'inherit'};
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: ${({ bottom }) => bottom || '1rem'};
`

export const Label = styled.h6`
  opacity: 0.4;
  text-align: ${({ align }) => align || 'inherit'};
  font-weight: 600;
  font-size: 0.7rem;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.75em;
`

export const PageTitle = styled.h1`
  background: none;
  margin: 0;
  text-align: ${({ align }) => align || 'center'};
  font-size: 1.25rem;
  font-weight: 400;
`
