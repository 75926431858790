import React from 'react'
import { StyledLinkA } from './link-group'
import { StaticQuery, graphql } from 'gatsby'

import { Icon } from '../icon'
import { ReactComponent as Youtube } from '../../images/svg/youtube.svg'
import { ReactComponent as Vimeo } from '../../images/svg/vimeo.svg'
import { ReactComponent as Facebook } from '../../images/svg/facebook.svg'
import { ReactComponent as Instagram } from '../../images/svg/insta.svg'

const SocialMedia = ({ size }) => (
  <StaticQuery
    query={graphql`
      query SocialMedia {
        contact {
          social {
            type
            display
            url
          }
        }
      }
    `}
    render={data => {
      const { social } = data.contact
      if (!Array.isArray(social)) {
        console.warn(`expect 'social' to be an Array`)
        return
      }
      return (
        <div>
          {social.map(({ type, display, url }) => {
            if (!display) return
            let SocialIcon
            switch (type) {
              case 'youtube':
                SocialIcon = Youtube
                break
              case 'instagram':
                SocialIcon = Instagram
                break
              case 'vimeo':
                SocialIcon = Vimeo
                break
              case 'facebook':
                SocialIcon = Facebook
                break
              default:
                SocialIcon = Youtube
            }
            return (
              <StyledLinkA
                target="_blank"
                rel="noopener noreferrer"
                key={type}
                href={url}
              >
                <Icon size={size} icon={<SocialIcon />} />
              </StyledLinkA>
            )
          })}
        </div>
      )
    }}
  />
)

export { SocialMedia }
