import styled from '@emotion/styled'

const Divider = styled.hr`
  display: inline-block;
  max-width: 12rem;
  width: 60%;
  border: none;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
`

export default Divider
